import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Image from "gatsby-image";
import { kebabCase, getGatsbyImage } from "helpers";

/* Article Banner
============================================================================= */

const Banner = ({ image, title }) => {
	// Config

	// Render
	return (
		<div className="w-full relative" style={{ height: "450px" }}>
			<Image
				fluid={getGatsbyImage(image)}
				alt={image.alt}
				placeholderStyle={{
					filter: "blur(50px)",
				}}
				style={{
					position: "absolute",
					top: 0,
					bottom: 0,
					width: "100%",
					height: "100%",
				}}
				imgStyle={{
					objectFit: "cover",
					objectPosition:
						title ===
						"Introducing Litmus Media hosts: Mahmood Fazal & Daniel Stewart"
							? "50% 20%"
							: "50% 40%",
				}}
			/>
		</div>
	);
};

/* Export
============================================================================= */

export default Banner;
