import React from "react";
import { getGatsbyImage, getDate } from "helpers";
import { Twitter, Facebook } from "react-social-sharing";

/* Article Heading
============================================================================= */

const ArticleHeading = ({ title, author, date, location }) => {
	// Config

	// Render
	return (
		<>
			<h2 className="mx-auto font-display text-4xl text-center text-black pb-2 leading-tight 2xl:w-4/5 lg:w-2/3 padding-top">
				{title}
			</h2>
			<h4 className="font-body text-sm uppercase text-center tracking-wider text-gray-800 ">
				By{" "}
				<span className="font-semibold text-gold">{author.name}</span>{" "}
				&bull; <time>{getDate(date)}</time>
			</h4>
			<ul
				role="list"
				aria-label="Article Sharing Options"
				className="pt-6 pb-6 col-span-5 flex flex-row items-start flex-wrap justify-start hidden"
			>
				<p className="tiresias text-xl font-bold mr-6">Share</p>
				<li className="social-sharing-twitter">
					<Twitter link={location.href} />
				</li>
				<li className="social-sharing-facebook">
					<Facebook link={location.href} />
				</li>
			</ul>
		</>
	);
};

/* Export
============================================================================= */

export default ArticleHeading;
