import React, { useEffect } from "react";
import { Link } from "gatsby";
import { SEO, serializers } from "components";
import Image from "gatsby-image";
import Helmet from "react-helmet";
import { Heading, Banner, Author } from "components/article";
import BlockContent from "@sanity/block-content-to-react";
import useMenu from "state/useMenu";
/* Article Template
============================================================================= */

const ArticleTemplate = ({ pageContext, location }) => {
	// Config
	const { article } = pageContext;
	const { setAd } = useMenu();

	useEffect(() => {
		setAd();
	}, []);
	// Render
	return (
		<>
			<Helmet title={article.title}>
				<meta name="description" content={article.blurb} />
				<meta name="image" content={article.image.image.asset.url} />
				<meta property="og:title" content={article.title} />
				<meta
					property="og:image"
					content={article.image.image.asset.url}
				/>
				<meta name="twitter:card" content="summary_large_image" />
				<meta
					name="twitter:image"
					content={article.image.image.asset.url}
				/>
				<meta property="og:url" content={location.href} />
				<meta property="og:description" content={article.blurb} />
				<meta property="og:type" content="article" />
			</Helmet>
			<div className="flex flex-col items-center justify-start w-full mx-auto padding-bottom">
				<Heading
					title={article.title}
					author={article.author}
					date={article._createdAt}
					location={location}
				/>
			</div>
			<Banner image={article.image} title={article.title} />
			<section
				role="region"
				aria-label={article.title}
				className="horizontal-padding padding-top padding-bottom"
			>
				<div className="w-full pb-20 mx-auto">
					<BlockContent
						blocks={article._rawContent}
						serializers={serializers}
						className="prose-lg block-content"
						renderContainerOnSingleChild
					/>
				</div>
			</section>
		</>
	);
};

/* Export
============================================================================= */

export default ArticleTemplate;
